import React from 'react';

import { get, map } from '@dop/shared/helpers/immutableHelpersWithArgs';
import { ThemaIcon } from '@dop/ui-icons/themaIcon';
import { isEmpty, isFilled } from '@dop/shared/helpers/immutableHelpers';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import {
	mqWidth1120px,
	mqWidth640px,
} from '@dop/ui-primitives/base/mediaQueryDefinitions';

import { BlockLink } from '../../link/blockLink';
import { Heading } from '../heading';
import { Link } from '../../link/link';
import { hemelblauw } from '../../../styles/colors';

const iconSize = '3rem';

const MainLinkBlock = ({ href, text, links, icon }) => (
	<Box>
		<Heading
			level={3}
			css={`
				color: ${hemelblauw.grijscontrast};
			`}
		>
			<BlockLink
				to={href}
				css={`
					&:hover {
						border: none;
						fill: ${hemelblauw.darkest};
					}
				`}
				{...addTestSelector('uiMainBlocks')}
			>
				<ThemaIcon
					aria-hidden
					icon={icon}
					css={`
						width: ${iconSize};
						height: ${iconSize};
						margin-bottom: 0.2rem;
						fill: currentColor;
						color: ${hemelblauw.darkest};
					`}
				/>
				<BlockLink.Underliner
					css={`
						font-size: 1.3rem;
					`}
					{...addTestSelector(`uiMainBlock-${text}`)}
				>
					{text}
				</BlockLink.Underliner>
			</BlockLink>
		</Heading>
		{isFilled(links) && (
			<div
				css={`
					margin-top: 0.4rem;
				`}
			>
				{map((link, index) => {
					return (
						<React.Fragment key={index}>
							<Link
								to={get('href')(link)}
								css={`
									border: none;
									font-size: 0.9rem;
									&:hover {
										border-bottom: 1px solid currentColor;
									}
								`}
							>
								{get('text')(link)}
							</Link>
							{', '}
						</React.Fragment>
					);
				})(links)}
				{'…'}
			</div>
		)}
	</Box>
);

export const MainLinkBlocks = ({ linkList, className }) => {
	if (isEmpty(linkList)) return null;

	return (
		<Grid
			className={className}
			$gap={[1.5, 3]}
			$columns="1fr"
			$mq={{
				[mqWidth640px.min]: { $columns: 'repeat(2, 1fr)' },
				[mqWidth1120px.min]: {
					$columns: 'repeat(3, 1fr)',
				},
			}}
		>
			{map((item, key) => <MainLinkBlock key={key} {...item?.toObject()} />)(
				linkList
			)}
		</Grid>
	);
};
