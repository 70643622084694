import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { NegativeColor, PositiveColor } from '../colorTheme/ColorTheme.types';
import { getNormalBorderColorCSS } from './planeDivisionStyleFunctions';

export type RulerStaticStyleProps = BaseStaticStyleProps & {
	$borderWidth?: CSSProperty.BorderWidth;
	$borderColor?: PositiveColor | NegativeColor;
};

export type RulerComponentStyleProps =
	ComponentStyleProps<RulerStaticStyleProps>;

export const rulerStaticStyleMap: StyleMap<RulerStaticStyleProps> = {
	...baseStaticStyleMap,
	$borderWidth: (borderWidth = '2px') =>
		css`
			border-block-start-width: ${borderWidth};
		`,
	$borderColor: (borderColor = ['positive', 'muted']) =>
		getNormalBorderColorCSS(borderColor),
};

const rulerStyleMap: ComponentStyleMap<RulerStaticStyleProps> = {
	normal: rulerStaticStyleMap,
};

/**
 * Devide stacked elements with a horizontal line.
 *
 * Responsibilities:
 *
 * 1. Show a horizontal line.
 * 2. Set thickness of line.
 * 3. Set color of line.
 */
export const Ruler = styled.i<RulerComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			border-block-start-style: solid;

			${getCSSFromStyleProps(rulerStyleMap, props)};
		`;
	}};
`;

export type RulerProps = StyledComponentPropsWithoutRef<typeof Ruler>;
