import React from 'react';

import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconSluiten: SvgContent = ({
	cropped = false,
	...props
}): React.ReactElement | null => (
	<svg
		role="img"
		aria-hidden
		focusable={false}
		{...(cropped
			? { viewBox: '8 8 47.42 47.42', width: '47.42', height: '47.42' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g>
			<path d="M55.4193548,49.933801 L37.1948003,31.7092464 L55.4193548,13.4855538 L49.933801,8 L31.7101084,26.2236926 L13.4855538,8 L8,13.4855538 L26.2245545,31.7092464 L8,49.933801 L13.4855538,55.4193548 L31.7101084,37.1948003 L49.933801,55.4193548 L55.4193548,49.933801" />
		</g>
	</svg>
);
