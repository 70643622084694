import React from 'react';

import { toObject } from '@dop/shared/helpers/immutableHelpersWithoutArgs';
import { LinkBlock } from '@dop/ui-composites/link/LinkBlock';
import { WithLinkTypeIcon } from '@dop/ui-composites/link/WithLinkTypeIcon';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { Image } from '@dop/ui-composites/media/Image';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { Heading } from '@dop/ui-composites/typography/Heading';

const AnkeilerText = ({
	title,
	text,
	header,
	href,
}: {
	title: string;
	text: string;
	header: string;
	href: string;
}) => (
	<Stack $gap={2}>
		<Heading
			level={3}
			$order={1}
			$fontWeight="normal"
			$hover={{ $textDecorationLine: 'underline' }}
			$color={['text', 'secondary']}
		>
			{title}
		</Heading>
		<TextBlock
			$order={0}
			$fontWeight="bold"
			$hover={{ $textDecorationLine: 'underline' }}
		>
			{header}
		</TextBlock>
		<TextBlock
			$order={2}
			$hover={{ $textDecorationLine: 'underline' }}
			$capSize={0.875}
		>
			<WithLinkTypeIcon href={href}>{text}</WithLinkTypeIcon>
		</TextBlock>
	</Stack>
);

export const PageBlockFigure = ({ src, alt }: { src: string; alt: string }) => {
	if (!src || !alt) return null;

	return (
		<Image
			loading="lazy"
			src={src}
			alt={alt}
			$objectFit="cover"
			{...addTestSelector('uiPromoBlockImage')}
		/>
	);
};

type PageBlockAnkeilerProps = {
	header: string;
	title: string;
	ankeiler: string;
	image: string;
	href: string;
};

export const PageBlockAnkeiler = ({
	header,
	title,
	ankeiler,
	image,
	href,
}: PageBlockAnkeilerProps) => (
	<LinkBlock
		href={href}
		linkType="pageBlockAnkeiler"
		{...addTestSelector('uiPromoBlock')}
	>
		<PageBlockFigure {...toObject(image)} />
		<AnkeilerText href={href} title={title} text={ankeiler} header={header} />
	</LinkBlock>
);
