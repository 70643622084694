import React from 'react';
import styled, { css } from 'styled-components';
import { Property as CSSProperty } from 'csstype';

import { StyledComponentPropsWithoutRef } from '@dop/shared/typeHelpers/StyledComponentPropsWithoutRef';
import { hasWindow } from '@dop/shared/helpers/windowDocument';
import { neutral } from '@dop/ui-primitives/color/colors';

import {
	BaseStaticStyleProps,
	baseStaticStyleMap,
	baseStyle,
} from '../base/Base';
import { getCSSFromStyleProps } from '../base/getCSSFromStyleProps';
import {
	ComponentStyleMap,
	ComponentStyleProps,
	StyleMap,
} from '../base/StyleProps.types';
import { getStringOrRootCapCSSValue } from '../base/commonStyleFunctions';

type MarginSide = number | CSSProperty.MarginBlockStart;

export type DialogStaticStyleProps = BaseStaticStyleProps & {
	$margin?:
		| MarginSide
		| [MarginSide, MarginSide]
		| [MarginSide, MarginSide, MarginSide, MarginSide];
};

export type DialogComponentStyleProps =
	ComponentStyleProps<DialogStaticStyleProps>;

export const dialogStaticStyleMap: StyleMap<DialogStaticStyleProps> = {
	...baseStaticStyleMap,
	$maxInlineSize: (maxInlineSize = `calc(100% - var(--root-cap) * 2)`) =>
		baseStaticStyleMap.$maxInlineSize(maxInlineSize),
	$maxBlockSize: (maxBlockSize = `calc(100% - var(--root-cap) * 2)`) =>
		baseStaticStyleMap.$maxBlockSize(maxBlockSize),

	$margin: (margin) => {
		if (margin == null) {
			return css`
				margin: auto;
			`;
		}

		if (Array.isArray(margin)) {
			const marginPropertyValues = margin.map((marginEntry) => {
				return getStringOrRootCapCSSValue(marginEntry);
			});
			if (marginPropertyValues.length === 4) {
				const [
					marginBlockStart,
					marginInlineEnd,
					marginBlockEnd,
					marginInlineStart,
				] = marginPropertyValues;
				return css`
					margin-inline: ${marginInlineStart} ${marginInlineEnd};
					margin-block: ${marginBlockStart} ${marginBlockEnd};
				`;
			}

			const [marginBlock, marginInline] = marginPropertyValues;

			return css`
				margin-inline: ${marginInline};
				margin-block: ${marginBlock};
			`;
		}
		return css`
			margin-inline: ${margin};
			margin-block: ${margin};
		`;
	},
};

const dialogStyleMap: ComponentStyleMap<DialogStaticStyleProps> = {
	normal: dialogStaticStyleMap,
};

const DialogStyled = styled.dialog<DialogComponentStyleProps>`
	${(props) => {
		return css`
			${baseStyle};
			padding: unset;
			border: unset;
			overscroll-behavior: contain;
			::backdrop {
				opacity: 0.5;
				background-color: ${neutral.black};
			}
			${getCSSFromStyleProps(dialogStyleMap, props)};
		`;
	}};
`;

export type DialogProps = Omit<
	StyledComponentPropsWithoutRef<typeof DialogStyled>,
	'onClose' | 'onCancel'
> & {
	opened: boolean;
	onClose: (event: Event) => void;
	onCancel: (event: Event) => void;
};

const Dialog_ = (
	{ opened, onClose, onCancel, ...props }: DialogProps,
	forwardedRef: React.ForwardedRef<HTMLDialogElement>
) => {
	const localRef = React.useRef<HTMLDialogElement>(null);
	const ref =
		forwardedRef != null && 'current' in forwardedRef ? forwardedRef : localRef;

	React.useEffect(() => {
		if (!hasWindow()) return;

		const handleCancel = (event: Event) => {
			event.preventDefault();
			onCancel(event);
		};

		const handleClose = (event: Event) => {
			event.preventDefault();
			onClose(event);
		};
		const handleBackdropClose = (event: Event) => {
			if (event.target !== event.currentTarget) return;

			onCancel(event);
		};
		const modalElement = ref.current;

		if (modalElement == null) return;

		modalElement.addEventListener('click', handleBackdropClose);
		modalElement.addEventListener('close', handleClose);
		modalElement.addEventListener('cancel', handleCancel);

		return () => {
			modalElement.removeEventListener('click', handleBackdropClose);
			modalElement.removeEventListener('close', handleClose);
			modalElement.removeEventListener('cancel', handleCancel);
		};
	}, [onClose, onCancel, ref]);
	React.useEffect(() => {
		if (!hasWindow()) return;

		if (opened && !ref.current?.open) {
			ref.current?.showModal();
			return;
		}
		if (!opened && ref.current?.open) {
			ref.current?.close();
			return;
		}
	}, [opened, ref]);

	return <DialogStyled ref={ref} {...props} />;
};

/**
 * Component for showing popups using the native HTML `<dialog>` element.
 *
 * Responsibilities:
 *
 * 1. Use native `<dialog>` element to show and hide modals.
 * 2. Leverage native functionality to manage focus.
 * 3. Leverage native functionality to close dialog when the `escape` key is pressed.
 * 4. Style backdrop color and opacity.
 * 5. Make sure the dialog fits within the window and can be scrolled if necessary.
 * 6. Place the dialog within the window using margin.
 *
 * Usage:
 *
 * Dialog can be opened as modal via required boolean property `opened`.
 *
 * Always provide both a `onClose` and `onCancel` callback to handle opened state changes
 * when the dialog is closed or cancelled (which also closes it).
 */
export const Dialog = React.forwardRef(Dialog_);
