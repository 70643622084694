import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';

import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { MainRowLayout } from '@dop/shared/components/pageLayout/MainRowLayout';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { getIn } from '@dop/shared/helpers/immutableHelpersWithArgs';
import {
	ShelfLinkBlock,
	ShelfLinkIcon,
	ShelfLinkText,
} from '@dop/ui-composites/link/ShelfLink';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { Rows } from '@dop/ui-primitives/layout/Rows';
import {
	mqWidth1296px,
	mqWidth880px,
} from '@dop/ui-primitives/base/mediaQueryDefinitions';

export const useCoronaInfo = () => {
	const coronaInfo = usePageDataSelector(getIn(['alertBanner']));

	return coronaInfo;
};

export const CoronaBar = ({ $gridArea = undefined }) => {
	const coronaInfo = useCoronaInfo();

	if (coronaInfo == null) {
		return null;
	}

	const { title, subtitle, link } = coronaInfo.toJSON();

	return (
		<Box
			$padding={[1.5, 0]}
			as="section"
			$backgroundColor={['negative', 'secondary']}
			{...addTestSelector('uiCoronaBar')}
			$gridArea={$gridArea}
		>
			<MainRowLayout
				mainSlot={
					<Rows
						$gap={2}
						$blockSize="100%"
						$alignItems="center"
						$justifyItems="center"
					>
						<TextBlock
							$mq={{
								[mqWidth880px.min]: { $capSize: 1.4 },
								[mqWidth1296px.min]: { $capSize: 1.3 },
							}}
							$fontWeight="bold"
							$capSize={1.5}
						>
							{title} {subtitle}
						</TextBlock>
						{link != null && (
							<ShelfLinkBlock href={link.href}>
								<ShelfLinkText $capSize={1.2}>{link.text}</ShelfLinkText>
								<ShelfLinkIcon as={IconChevronRight} />
							</ShelfLinkBlock>
						)}
					</Rows>
				}
			/>
		</Box>
	);
};
